import React, { lazy, Suspense, useContext, useState } from "react";
import {
  Grid,
  useTheme,
  useMediaQuery,
  AppBar,
  IconButton,
  Toolbar,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import TopNavBar from "./components/common/TopNavBar";
import Sidebar from "./components/common/sidebar/Sidebar";
import Homepage from "./components/homepage/Homepage";
import LoginSuccess from "./components/LoginSuccess";
import ConfirmQuote from "./components/ConfirmQuote";
import UserSettings from "./components/UserSettings";
import RoleGuard from "./components/guards/RoleGuard";
import { UserContext } from "./components/AuthGuard";
import logo from "./assets/logo.svg";
import ToReadMore from "./components/ToReadMore";
import { LoggedInRouter } from "./LoggedInRouter";

/**
 * Lazy load components to improve performance and reduce initial bundle size
 * this components are not needed on the first load or are not used by all users
 */
const SignInScreen = lazy(() => import("./components/SignInScreen"));
const NotFoundPage = lazy(() => import("./components/NotFoundPage"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
const Team = lazy(() => import("./components/Team"));
const About = lazy(() => import("./components/About"));
const PublicMarketNews = lazy(() => import("./components/PublicMarketNews"));
const NewsPost = lazy(() => import("./components/NewsPost"));

const generalRoutes = [
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/login",
    element: <SignInScreen />,
  },
  {
    path: "/team",
    element: <Team />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/news",
    element: <PublicMarketNews />,
  },
  {
    path: "/market-news",
    element: <ToReadMore />,
  },
  {
    path: "/news-post/:id",
    element: <NewsPost />,
  },
  {
    path: "/verify/:token/:accept",
    element: <ConfirmQuote />,
  },
  {
    path: "/login-success",
    element: (
      <RoleGuard roles={["admin", "write", "read", "none"]}>
        <LoginSuccess />
      </RoleGuard>
    ),
  },
  {
    path: "/user",
    element: (
      <RoleGuard roles={["admin", "write", "read", "none"]}>
        <UserSettings isNotVerified={true} />
      </RoleGuard>
    ),
  },
  {
    path: "/*",
    element: <NotFoundPage />,
  },
];

const StyledRoot = styled(Grid)(({ }) => ({
  display: "grid",
  maxHeight: `${window.innerHeight}px`,
  height: `${window.innerHeight}px`,
}));

const StyledMain = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'matches',
})(({ matches }) => ({
  padding: window.location.pathname.substring(1) === "ship-tracking" ? 0 : "25px",
  minHeight: `${window.innerHeight}px`,
  overflow: "scroll",
  marginTop: matches ? 0 : "50px",
}));

const RenderUnauthScreens = () => {
  return (
    <>
      <TopNavBar />
      <Routes>
        {generalRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </>
  );
};

const RootWithSidebar = ({ children, sidebarOpen, matches, handleSidebarOpen, handleMobileOpen, mobileOpen }) => {
  return (
      <StyledRoot
      open={sidebarOpen}
      gridTemplateColumns={
        matches ? (sidebarOpen ? "250px 1fr" : "95px 1fr") : "12fr"
      }
    >
      {!matches && <AppBar
        sx={{
          background:
            "linear-gradient(100deg, rgb(0, 18, 85) 50%, rgb(115 221 201) 100%)",
          display: {
            xs: "block",
            md: "none",
          },
        }}
      >
        <Toolbar
          sx={{
            flexWrap: "nowrap",
            justifyContent: "space-between",
          }}
        >
          <img src={logo} alt="logo" width="150px" />
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{
              display: {
                xs: "block",
                md: "none",
              },
            }}
            onClick={handleMobileOpen}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>}
      {(matches || mobileOpen) && <Sidebar
        matches={matches}
        mobileOpen={mobileOpen}
        large={sidebarOpen}
        handleOpen={handleSidebarOpen}
        handleMobileOpen={handleMobileOpen}
        />}
        {children}
    </StyledRoot>
  );
}

const Main = () => {
  const [user] = useContext(UserContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(() =>
    !matches ? true : localStorage.getItem("sidebarOpen") === "true",
  );

  if (!user || !user.role || user.role === "none")
    return <RenderUnauthScreens />;

  const handleSidebarOpen = () => {
    const opened = !sidebarOpen;
    setSidebarOpen(opened);
    localStorage.setItem("sidebarOpen", opened);
  };

  const handleMobileOpen = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <RootWithSidebar sidebarOpen={sidebarOpen} matches={matches} handleSidebarOpen={handleSidebarOpen} handleMobileOpen={handleMobileOpen} mobileOpen={mobileOpen}>
      <Suspense fallback={
        <RootWithSidebar sidebarOpen={sidebarOpen} matches={matches} handleSidebarOpen={handleSidebarOpen} handleMobileOpen={handleMobileOpen} mobileOpen={mobileOpen}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </div>
        </RootWithSidebar>
      }>
        <StyledMain matches={matches}>
          <LoggedInRouter />
        </StyledMain>
      </Suspense>
    </RootWithSidebar>
  );
};

export default Main;
